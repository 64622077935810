import React, {useMemo} from "react";
import {useLocation, useNavigate, useRouteLoaderData} from "react-router-dom";

import VerifyEmail from "../components/verifyEmail";
import VerifySettings from "../components/verifySettings";
import {RootLoaderType} from "../types";
import {Text} from "../components/catalyst/text";
import {Heading, Subheading} from "../components/catalyst/heading";
import {Divider} from "../components/catalyst/divider";
import {Button} from "../components/catalyst/button";

import {ArrowUturnLeftIcon} from '@heroicons/react/16/solid'
import {Badge} from "../components/catalyst/badge";

export default function WeiterleitenPage() {

    const data = useRouteLoaderData("root") as RootLoaderType;

    const { refreshToken, accessToken } = useRouteLoaderData("root") as RootLoaderType;

    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = (link: string) => {
        // Get the current search params
        const searchParams = new URLSearchParams(location.search);
        navigate(`${link}?${searchParams.toString()}`);
    };


    const isAllowedToLogin = useMemo(()=>{

        return !!data?.user?.name && !!data?.user?.billingEmail && !!data?.user?.billingEmailVerified;

    },[data])

    return (
        <div
            className={"grid h-full w-full md:max-w-lg mx-auto shadow-md rounded-xl border-2 overflow-hidden place-items-start justify-items-center p-6 py-8 sm:p-8 lg:p-12"}>
            <VerifySettings/>
            <VerifyEmail needsVerification={((!data?.user?.billingEmailVerified) && !!data?.user?.billingEmail)}/>
            <div className=" flex gap-5 flex-col">

                <Heading className={"text-center"}>Angemeldet als {data?.user?.name}</Heading>

                <div>


                    <section className="flex gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div className="space-y-2 grow">
                            <Subheading>Website</Subheading>
                            <Text>Melde dich auf der JUHUU Website an.</Text>
                        </div>
                        <div className={"self-center"}>
                            <Button
                                onClick={() => {

                                    window.open("https://juhuu.app");
                                }}
                                className="">
                                Weiter
                            </Button>
                        </div>
                    </section>
                    <Divider className="my-10 mt-6"/>
                    <section className="flex gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div className="space-y-2 grow">
                            <Subheading>App</Subheading>
                            <Text> Melde dich bei der JUHUU App an, um alle integrierten Services zu nutzen.</Text>
                        </div>
                        <div className={"self-center"}>
                            <Button
                                disabled={!isAllowedToLogin}
                                onClick={() => {
                                    if (!isAllowedToLogin) {
                                        return;
                                    }
                                    const params = new URLSearchParams();
                                    params.set("refreshToken", refreshToken);
                                    params.set("accessToken", accessToken);
                                    window.open("juhuu://auth?" + params.toString());
                                }}
                                className="">
                                Weiter
                            </Button>
                        </div>
                    </section>
                    <Divider className="my-10 mt-6"/>
                    <section className="flex gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div className="space-y-2 grow">
                            <Subheading>Marketplace </Subheading>
                            <Text> Melde dich im JUHUU Marketplace an, um Produkte zu listen.
                            </Text>
                        </div>
                        <div className={"self-center"}>
                            <Button
                                onClick={() => {
                                    if (!isAllowedToLogin) {
                                        return;
                                    }
                                    const params = new URLSearchParams();
                                    params.set("refreshToken", refreshToken);
                                    params.set("accessToken", accessToken);
                                    window.open("https://marketplace.juhuu.app/login?" + params.toString());
                                }}
                                disabled={false}
                                className="">
                                Weiter
                            </Button>
                        </div>
                    </section>
                    <Divider className="my-10 mt-6"/>
                    <section className="flex gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div className="space-y-2 grow">
                            <Subheading>Dashboard</Subheading>
                            <Text> Melde dich als Admin an, um deine Services auf der JUHUU Plattform zu verwalten. </Text>
                        </div>
                        <div className={"self-center"}>
                            <Button
                                onClick={() => {
                                    if (!isAllowedToLogin) {
                                        return;
                                    }
                                    const params = new URLSearchParams();
                                    params.set("refreshToken", refreshToken);
                                    params.set("accessToken", accessToken);
                                    window.open("https://dashboard.juhuu.app/?" + params.toString());
                                }}
                                className="">
                                Weiter
                            </Button>
                        </div>
                    </section>
                    <Divider className="my-10 mt-6"/>
                    <section className="flex gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div className="space-y-2 grow">
                            <Subheading>localhost:3000 <Badge color="violet">Developer only</Badge></Subheading>
                            <Text>Nur für Developer relevant.</Text>
                        </div>
                        <div className={"self-center"}>
                            <Button
                                onClick={() => {

                                    if (!isAllowedToLogin) {
                                        return;
                                    }
                                    const params = new URLSearchParams();
                                    params.set("refreshToken", refreshToken);
                                    params.set("accessToken", accessToken);
                                    window.open("http://localhost:3000/?" + params.toString());

                                }}
                                className="">
                                Weiter
                            </Button>
                        </div>
                    </section>


                </div>

                <Button outline onClick={() => handleClick("/auth")}
                        className="">
                    <ArrowUturnLeftIcon/> Zurück


                </Button>

            </div>
        </div>
    );
}
